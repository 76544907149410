<template>
  <div class="profilerDescription">
    <div class="main">
      <p>{{ title }}</p>
    </div>
    <div class="sub">
      <p>{{ subTitle }}</p>  
    </div>
  </div>
</template>

<script>
import { concatWithAnd } from '../utils/functionUtils'

export default {
  props: {
    families: {
      type: Array,
      required: true,
    },
    subFamilies: {
      type: Array,
      required: true,
    },
  },
  computed: {
    title() {
      var text =
        this.families.length > 1
          ? this.$t('recommendation_general_title_plural')
          : this.$t('recommendation_general_title_singular')
      return text.replace(
        '%FAMILIES%',
        concatWithAnd(
          this.families.map(f => f.name),
          this.$t('recommendation_and')
        )
      )
    },
    subTitle() {
      var text =
        this.families.length > 1
          ? this.$t('recommendation_general_sub_title_plural')
          : this.$t('recommendation_general_sub_title_singular')
      return text.replace(
        '%FAMILIES%',
        concatWithAnd(
          this.subFamilies.map(f => f.name),
          this.$t('recommendation_and')
        )
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.profilerDescription
  display: flex
  flex-direction: column
  max-width: vw(350px)

  .mobile &
    display: block

  .main,
  .sub
    display: inline

    .mobile &
      display: block

    // .fam-group,
    .sub-fam-group
      display: inline-block
      // white-space: pre

    .fam-word,
    .sub-fam-word
      m-first-letter-case()
      display: inline-block
      // white-space: pre
      cursor: pointer

      &.selected
        text-decoration: underline
        font-weight: bold

  .main
    m-font-size(25, 35)
    font-weight: 700
    // font-family: "Playfair Display"

    .mobile &
      m-font-size(23, 29)
      margin-bottom: vw(20px)
      font-weight: 700
      font-family: "AvenirNext"

  .sub
    m-font-size(18, 26)
    margin-bottom: vh(20px)

    .mobile &
      m-font-size(16, 22)
      >>>p
        margin-bottom 0
</style>