import { v1 as uuidv1 } from 'uuid'

export function getUserIdLocalStorage() {
  var userid = localStorage.getItem('tempId')
  if (userid === null) {
    userid = uuidv1()
    localStorage.setItem('tempId', userid)
  }
  return userid
}
