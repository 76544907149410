<template>
  <div :class="['recommendation-view', 'view', {'loading': isRecoUpdating && copyReco.length === 0 && isPerfumesLoading}]">
    <div v-if="!isPerfumesLoading" class="recommendation-info top">
      <div class="left">
        <profile-description :families="families" :sub-families="subFamilies" />
        <!-- Edit preferences btn. more that one -->
        <button v-if="!isMobile" :class="['btn btn--ghost', {'btn--small': isMobile}]" @click="handleClickEditPreferences">{{ $t("recommendation_edit_preferences") }}</button>
      </div>
      <div class="center">
        <div ref="donutWrapper" class="vertical-wrapper">
          <donut-border
            v-if="userFamilies.length>0"
            ref="dount"
            :data="userFamilies"
            :show-notes="false"
            :width="donutRadius"
            :height="donutRadius"
          />
        </div>
      </div>
      <div class="right">
        <div class="title">{{ $t('recommendation_your_favorite_ings') }}</div>
        <div class="ingredients-list">
          <ingredient-item
            v-for="ing in ingsInSelection"
            :key="ing.id"
            :data="ing"
          />
        </div>
        <!-- Edit preferences btn more that one-->
        <button v-if="isMobile" :class="['btn btn--ghost', {'btn--small': isMobile}]" @click="handleClickEditPreferences">{{ $t("recommendation_edit_preferences") }}</button>
      </div>
    </div>
    <basic-loading v-if="isRecoUpdating && copyReco.length === 0" />
    <template v-else>
      <div class="section top-recos">
        <section-title :title="$t('recommendation_top_6_title')" :sub-title="$t('recommendation_top_6_sub_title')" />
        <div class="perfume-container">
          <perfume-card
            v-for="perfume in topReco"
            :key="perfume.id"
            :perfume="perfume"
            @click="()=>handleClick(perfume)"
          />
        </div>
      </div>
      <div class="section more-recos">
        <section-title :title="$t('recommendation_more_reco_title')" :sub-title="$t('recommendation_more_reco_sub_title')" />
        <filtreable-recommendation :value.sync="filters" class="filters-container" />
        <div class="all-reco-info">{{ $t('recommendation_more_reco_all_reco') }} ({{ otherReco.length }})</div>
        <div class="perfume-container" :class="{'loading': isRecoUpdating}">
          <perfume-card
            v-for="perfume in otherReco"
            :key="perfume.id"
            :perfume="perfume"
            @click="()=>handleClick(perfume)"
          />
        </div>
      </div>
    </template>
    <not-loged-modal v-model="open" @next="handleGoToLogin" />
  </div>
</template>

<script>
import BasicLoading from '../components/basic/BasicLoading.vue'
import IngredientItem from '../components/IngredientItem.vue'
import NotLogedModal from '../components/NotLogedModal.vue'
import PerfumeCard from '../components/PerfumeCard.vue'
import FiltreableRecommendation from '../components/RecommendationComponents/FiltreableRecommendation.vue'
import SectionTitle from '../components/SectionTitle.vue'
import QueryStringMixin from '../mixins/QueryStringMixin'
import TrackingMixin from '../mixins/TrackingMixin'
import { CONCEPT_SETTING, ROUTER_NAMES } from '../settings'
import {
  getFamiliesWithSubFam,
  getInfoFromEanData,
  getUsesFamilies,
} from '../utils/perfumeFunctions'
import { trackEvent } from '../services/oldTrack'
import { mapGetters } from 'vuex'
//import Donut from '../components/Donut.vue'
import DonutBorder from '../components/DonutBorder'
import ProfileDescription from '../components/ProfileDescription.vue'
import createWatchErrorMixin from '../mixins/createWatchErrorMixin'
export default {
  components: {
    IngredientItem,
    SectionTitle,
    PerfumeCard,
    FiltreableRecommendation,
    NotLogedModal,
    BasicLoading,
    //Donut,
    DonutBorder,
    ProfileDescription,
  },
  remoteComputed: {
    perfumes: {
      model: 'perfumes/search',
      method: 'getById',
      params() {
        return [this.qsSelectedPerfumes]
      },
    },
    reco: {
      model: 'perfumes/reco',
      params() {
        return [
          this.qsSelectedPerfumes,
          [this.qsGender],
          this.filters.brand,
          this.qsConcepts,
          this.filters.families,
          this.numReco,
        ]
      },
    },
    brands: {
      model: 'brands/index',
    },
  },
  mixins: [QueryStringMixin, TrackingMixin,createWatchErrorMixin(['perfumesError','recoError'])],
  data() {
    return {
      open: false,
      filters: {
        families: [],
        brand: [],
      },
      topReco: [],
      copyReco: [],
      numReco: 18,
      size: { width: 450, height: 450 },
    }
  },

  computed: {
    ...mapGetters({ user: 'profiler/user' }),
    donutRadius() {
      if (this.isMobile) return Math.min(this.size.width, this.size.height * 1)
      else return Math.min(this.size.width * 0.9, this.size.height * 0.9)
    },
    isPerfumesLoading(){
      return this.perfumes.filter(p => p).length !== this.qsSelectedPerfumes.length
    },
    userFamilies() {
      if (this.perfumes) {
        let data = getUsesFamilies(this.perfumes.filter(p => p))
        return data
      }
      return []
    },
    familiesWithSubFam() {
      return getFamiliesWithSubFam(this.perfumes)
    },
    otherReco() {
      if (
        this.topReco
          .map(perfume => perfume.id)
          .every((id, index) => this.copyReco[index] && this.copyReco[index].id === id)
      )
        return this.copyReco.slice(6)
      return this.copyReco
    },
    families() {
      return this.familiesWithSubFam.map(f => f.family)
    },
    subFamilies() {
      let subs = this.familiesWithSubFam.map(f => f.subFamilies.map(s => s.subFamily)).flat() // aqui llistes totes les subfamilies de qualsevol familia en la mateixa array
      subs = subs.filter((s, i) => subs.findIndex(s => s.id === subs[i].id) === i) // elimines subfam repes
      subs = subs.filter(s => this.familiesWithSubFam.findIndex(f => f.family.id === s.id) < 0) // elimines les subfams q es diuen igual q una fam?
      subs = subs.slice(0, 3) // nomes pilles 3 subfam
      return subs
    },
    profileMainIngredients() {
      let dict = this.perfumes
        .filter(e => e)
        .reduce((accum, p) => {
          p.ingredientProportions
            .sort((a, b) => b.proportion - a.proportion)
            .slice(0, 5)
            .forEach(ing => {
              let id = ing.ingredient.id
              if (!accum[id]) accum[id] = { value: 0, ing: ing.ingredient }
              accum[id].value += ing.proportion
            })
          return accum
        }, {})
      let list = Object.values(dict).sort((a, b) => b.value - a.value)
      return list.map(pp => pp.ing).slice(0, 6)
    },
    ingsInSelection() {
      let all = this.profileMainIngredients
      if (!this.selectedFamily) return all
      let perfumes = this.favorites.filter(p => p.family.id === this.selectedFamily.id)
      let insel = all.filter(ing =>
        perfumes.find(p => p.ingredientProportions.find(ip => ip.ingredient.id === ing.id))
      )
      return insel
    },
    concepts() {
      return CONCEPT_SETTING.concepts
        .filter(
          concept =>
            this.qsConcepts.findIndex(qsConcept => qsConcept.toString() === concept.id.toString()) >
            -1
        )
        .map(concept => concept.trackName)
    },
  },

  watch: {
    reco: {
      handler() {
        if (!this.reco || (this.reco.length === 0 && this.isRecoUpdating)) return
        this.eventTracker.emitEvent('RECOMMENDATION_FETCH', {
          resultingProfile: {
            perfumes: this.qsSelectedPerfumes.map(p => parseInt(p)),
            concepts: this.qsConcepts,
            families: this.families.map(family => parseInt(family.id)),
            forWhom: this.qsForMe ? 'ME' : 'THEM'
          },
          filter: {
            families: this.filters.families,
            gender: this.qsGender,
            brands: this.filters.brand
          },
          topResults: this.reco.map(({ id }) => id).slice(0, 6),
        })
        if (this.topReco.length === 0 && this.reco.length !== 0) {
          // tracking
          /**
           * WARNING: we are using here the selected perfume response like we have already had.
           * If reco request is speeder than perfume request. then it will be bad
           */
          const getEanDatas = perfumes =>
            perfumes
              .map(perfume => getInfoFromEanData(perfume, 'douglasPerfumeId'))
              .filter(eanData => !!eanData)
          const getIdsFromEanDatas = eanDatas =>
            eanDatas.map(eanData => `${eanData.sku};${eanData.douglasPerfumeId}`)

          const eanDatasReco = getEanDatas(this.reco)
          const topRecoIds = getIdsFromEanDatas(eanDatasReco.slice(0, 6)).join(',')
          const nextRecoIds = getIdsFromEanDatas(eanDatasReco.slice(7)).join(',')
          const selectedPerfumes = getIdsFromEanDatas(getEanDatas(this.perfumes)).join(',')
          const families = this.families.map(family => family.name).join(',')
          const subFamilies = this.subFamilies.map(family => family.name).join(',')
          const concepts = this.concepts.join(',')

          trackEvent(
            'PROFILER_RECO',
            {
              userId: this.user?.id,
              gift: !this.qsForMe,
              selected_perfumes: selectedPerfumes,
              concepts,
              gender: this.qsGender,
              family_sub_family: families + ';' + subFamilies,
              top_6_recos: topRecoIds,
              next_6_recos: nextRecoIds,
            },
            this.eventTracker.uuid
          )
          this.topReco = this.reco.slice(0, 6)
          this.douglasTrack(this.topReco, this.perfumes)
        }
        this.copyReco = [...this.reco]
      },
      immediate: true,
    },
    isPerfumesLoading(){
      if(this.$refs.donutWrapper)
        this.observable.observe(this.$refs.donutWrapper)
    }
  },
  updated(){
     if(this.$refs.donutWrapper && !this.alreadyObserved){
      this.observable.observe(this.$refs.donutWrapper)
      this.alreadyObserved = true
    }
  },
  mounted() {
    this.eventTracker.emitEvent('VIEW_OPENED', { type: 'OWN_PROFILE' })
    if (this.$refs.donutWrapper)
      this.size = {
        width: this.$refs.donutWrapper.clientWidth,
        height: this.$refs.donutWrapper.clientHeight,
      }
    this.observable = new ResizeObserver(entries => {
      if(entries[0]){
        let entry = entries[0]
        this.size.height = entry.target.clientHeight
        this.size.width = entry.target.clientWidth
      }
    })
    if(this.$refs.donutWrapper){
      this.observable.observe(this.$refs.donutWrapper)
    }
  },
  methods: {
    handleClickEditPreferences() {
      this.$routerPush({ name: ROUTER_NAMES.SEARCH_FRAGRANCE })
    },
    handleClick(perfume) {
      this.$routerPush({
        name: ROUTER_NAMES.PERFUME,
        params: { id: perfume.id },
        query: this.$route.query,
      })
    },
    handleGoToLogin() {
      const callback = this.$route.fullPath
      this.$routerPush({
        name: ROUTER_NAMES.LOGIN,
        query: {
          callback,
        },
      })
    },
    getFamiliesFromSignedPerfumes(liked, disliked) {
      let fams = liked
        .filter(p => p.family && p.secondaryFamily)
        .map(p => {
          return {
            secondaryFamily: p.secondaryFamily || p.family,
            family: p.family,
            inverted: false,
          }
        })
      fams = fams.concat(
        disliked
          .filter(p => p.family && p.secondaryFamily)
          .map(p => {
            return {
              secondaryFamily: p.secondaryFamily || p.family,
              family: p.family,
              inverted: true,
            }
          })
      )
      var counts = {}
      fams.forEach(x => {
        counts[x.family.id] = counts[x.family.id] || {
          val: 0,
          positiveNotes: [],
          negativeNotes: [],
        }
        // counts[x.family.id].val+=x.inverted?-1:1;
        if (x.inverted) counts[x.family.id].negativeNotes.push(x.secondaryFamily)
        else counts[x.family.id].positiveNotes.push(x.secondaryFamily)
      })

      fams = fams.filter((v, i, a) => a.findIndex(f => f.family.id === v.family.id) === i)
      fams = fams.map(f =>
        Object.assign({}, f, {
          positiveNotes: counts[f.family.id].positiveNotes,
          negativeNotes: counts[f.family.id].negativeNotes,
        })
      )
      fams.forEach(f => {
        delete f.secondaryFamily
        var subcounts = {}
        f.positiveNotes.forEach(x => {
          subcounts[x.id] = subcounts[x.id] || { val: 0, id: x.id }
          subcounts[x.id].val += 1
        })
        f.negativeNotes.forEach(x => {
          subcounts[x.id] = subcounts[x.id] || { val: 0, id: x.id }
          subcounts[x.id].val -= 1
        })

        let notes = f.positiveNotes
        notes = notes.filter((v, i, a) => a.findIndex(f => f.id === v.id) === i)
        notes = notes.map(n => Object.assign({}, n, { value: Math.max(0, subcounts[n.id].val) }))
        notes = notes.filter(n => n.value > 0)
        f.notes = notes
        f.value = f.notes.length
      })
      return fams.sort((a, b) => b.value - a.value)
    },
  },
}
</script>

<style lang="stylus" scoped>
.recommendation-view
  position: relative
  overflow-x: hidden
  overflow-y: auto
  width: 100%
  height: 100%
  background-color: #fff

  .desktop &
    padding-top: $sub-header-height

  &.loading
    overflow: hidden

  .top
    display: flex
    align-items: center
    overflow: hidden
    min-height: vh(500px)

    .mobile &
      display: block
      margin-bottom: vw(40px)
      height: auto

    .left
      display: flex
      flex: 1 1 0%
      flex-direction: column
      justify-content: center
      overflow: hidden
      padding: vh(40px) vw(60px)

      @media (min-width: 1400px)
        flex: 0 0 35vw

      .mobile &
        margin-top: 0
        padding: vw(20px)
        height: auto

      .title
        m-f1ont-size(16, 26)
        margin-bottom: vh(20px)
        .mobile &
          m-font-size(16, 26)

    .btn
      width: vw(300px)

      .mobile &
        margin-right: auto
        margin-left: auto
        height: vw(50px)

    .center
      display: flex
      flex: 0 0 vw(360px)
      flex-direction: column
      align-items: center
      overflow: hidden
      height: vw(360px)

      @media (min-width: 1400px)
        flex: 0 0 30vw
        height: 30vw

      .mobile &
        height: 40vh

      .vertical-wrapper
        position: relative
        flex-direction: column
        align-items: center
        margin: auto
        width: 100%
        height: 100%

        .loading
          >>> .content
            margin: auto

        .main-donut-column
          flex: 0 0 40vw
          overflow: hidden

          .mobile &
            position: none
            margin: 0 // Le quito el margin para que quede "mejor" con el sticky-button

          .title
            m-font-size(24, 34)
            margin: 0 50px

            .mobile &
              m-font-size(20, 24)
              margin: 0 0 20px
              text-align: center
              font-weight: 300

          .donut
            display: flex
            justify-content: center

            .mobile
              display: block

        .donut
          position: absolute
          top: 50%
          margin: auto
          width: 100%
          height: 80%
          transform: translateY(-50%)

          .mobile &
            height: 100%

        .family-pointer
          position: relative
          display: flex
          flex-direction: column
          align-items: center
          margin-top: vh(15px)
          opacity: 1
          transition: opacity 0.4s

          .mobile &
            margin-bottom: vh(15px)

          &.hidden
            opacity: 0

          .donut-explanation
            m-first-letter-case()
            m-font-size(12, 14)
            position: absolute
            top: vh(50px)
            transition: opacity 0.4s ease-in-out

            &.hidden
              opacity: 0

          .arrow
            width: @height
            height: vw(20px)

            &:after
              m-triangle("up", #000, 10)

          .family-label
            position: relative
            height: 3vh

            .label
              $shift-x = vw(200px)
              position: absolute
              white-space: nowrap
              transform: translate(-50%, 0)

              &.shift-enter-active,
              &.shift-leave-active
                transition: transform 0.6s ease-in-out, opacity 0.4s

              &.shift-enter
                opacity: 0
                transition-delay: 0.6s
                transform: translate($shift-x, 0)

              &.shift-leave-to
                opacity: 0
                transform: translate(-$shift-x, 0)

        // EN MOBILE LA LSITA ES "OTRA", esta:
        .mobile-currents-wrapper
          width: 100%

          .currents-list
            position: relative
            display: flex
            display: flex
            flex-wrap: wrap
            justify-content: center
            overflow-x: auto
            border-top: 1px solid #F5F5F5
            transform: translate(0, 0)

            .perfume-card
              margin: vh(10px) vw(10px)
              width: "calc(50% - (%s * 2))" % vw(10px)
              height: 30vh

    .right
      display: flex
      flex: 1 1 0%
      flex-direction: column
      justify-content: flex-start
      align-items: center
      overflow: hidden
      // margin-top: vh(60px)
      padding: vh(40px) vw(40px)
      background-color: #fff

      @media (min-width: 1400px)
        flex: 0 0 35vw

      .mobile &
        padding: vw(20px)

      .title
        m-font-size(16, 22)
        margin-bottom: vh(40px)
        font-weight: 600

        .mobile &
          m-font-size(20, 26)
          margin-bottom: vw(20px)

      .ingredients-list
        display: flex
        flex-wrap: wrap
        overflow: hidden
        width: vw(300px)

        .mobile &
          margin-bottom: vh(30px)
          width: 100%

        .ingredient-item
          $margin-x = vw(5px)
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
          flex: 0 0 $width
          margin: vw(10px) $margin-x
          width: $width

          @media (min-width: 1000px)
            $margin-x = vw(5px)
            $percentage = percentage((1 / 3))
            $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
            flex: 0 0 $width
            margin: vw(10px) $margin-x
            width: $width

          .mobile &
            $margin-x = vw(5px)
            $percentage = percentage((1 / 3))
            $width = "calc(%s - (2 * %s))" % ($percentage $margin-x)
            flex: 0 0 $width
            margin: vw(10px) $margin-x
            margin-top: vw(10px)
            margin-bottom: vw(10px)
            width: $width

  .section
    &.top-recos
      .mobile &
        margin-bottom: vw(40px)

      .perfume-container
        margin-right: 20vw
        margin-left: 20vw

      .perfume-card
        $margin = vw(20px)
        $percentage = percentage((1 / 3))
        $width = "calc(%s - (2 * %s))" % ($percentage $margin)
        flex: 0 0 $width
        margin: $margin
        width: $width

    &.more-recos
      .perfume-container
        margin-right: vw(150px)
        margin-left: vw(150px)

      .perfume-card
        $margin = vw(20px)
        $percentage = percentage((1 / 4))
        $width = "calc(%s - (2 * %s))" % ($percentage $margin)
        flex: 0 0 $width
        margin: $margin
        width: $width

      .all-reco-info
        m-font-size(16, 20)
        margin-right: vw(150px)
        margin-bottom: vh(30px)
        margin-left: vw(150px)
        font-weight: 700
        font-family: "Avenir"

        .mobile &
          margin-right: vw(20px)
          margin-left: vw(20px)

    .perfume-container
      display: flex
      flex-wrap: wrap
      margin-bottom: vh(50px)

      .mobile &
        margin-right: 0
        margin-left: 0

      &.loading
        position: relative

        &:after
          position: absolute
          top: 0
          left: 0
          z-index: 100
          width: 100%
          height: 100%
          background-color: rgba(#fff, 0.5)
          content: ""

      .perfume-card
        cursor: pointer

        .mobile &
          $margin = vw(15px)
          $percentage = percentage((1 / 2))
          $width = "calc(%s - (2 * %s))" % ($percentage $margin)
          flex: 0 0 $width
          margin: $margin
          width: $width
          cursor: pointer
</style>