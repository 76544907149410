<template>
  <div
    class="perfume-card"
    itemscope
    itemtype="http://schema.org/Product"
    @click="$emit('click',$event)"
  >
    <families-with-icon
      itemprop="additionalProperty"
      class="family"
      :family="perfume.family"
      :subfamily="perfume.secondaryFamily"
    />
    <div
      class="image"
      itemprop="image"
      :style="'background-image: url(' + imageUrl + ')'"
    />
    <div itemprop="name" class="title">{{ brand }}</div>
    <div itemprop="brand" class="brand-container">
      <div itemprop="brand" :class="['brand']">{{ name }}</div>
    </div>
    <div v-if="classification" class="classification">{{ classification }}</div>
  </div>
</template>

<script>
import { getInfoFromEanData } from '../utils/perfumeFunctions'
import FamiliesWithIcon from './FamiliesWithIcon.vue'

export default {
  components: { FamiliesWithIcon },
  props: {
    perfume: {
      type: Object,
      required: true,
    },
  },
  computed: {
    classification() {
      return this.perfume
        ? getInfoFromEanData(this.perfume, 'classification', false)?.classification
        : ''
    },
    imageUrl() {
      const ean = Object.values(this.perfume.eanData).find(ean => ean.imageLink900)
      if (ean) return ean.imageLink900
      else return this.perfume.imageUrl
    },
    name(){
      if(!this.perfume) return ''
      var title = getInfoFromEanData(this.perfume, 'productBaseProductName', false)?.productBaseProductName
      return title ?? this.perfume.name
    },
    brand(){
      if(!this.perfume) return ''
      const ean = getInfoFromEanData(this.perfume, 'brandName', false)
      var title = ean?.brandName
      if(!title) console.warn('BRAND NOT FOUND IN PRODUCT FEED',this.perfume.eanData)
      return title ?? this.perfume.brand.name
    }
  },
}
</script>

<style lang="stylus" scoped>
.perfume-card
  padding: vh(10px) vw(10px)
  width: vw(200px)
  border: 1px solid #fff
  transition: border 0.15s ease-in-out

  &:hover
    border: 1px solid #e6e6e6

  .image
    margin-top: vw(30px)
    margin-bottom: vw(30px)
    padding-bottom: 100%
    width: 100%
    background-position: center center
    background-size: contain
    background-repeat: no-repeat

    .mobile &
      margin-right: auto
      margin-left: auto
      width: 70%

  .family
    margin-bottom: vh(5px)

  .title
    m-ellipsis(100%)
    m-font-size(14, 20)
    margin-bottom: vh(5px)
    text-transform: uppercase
    font-weight: 700

  .brand-container
    display: flex
    align-items: center

  .brand
    m-font-size(12, 16)
    text-transform: capitalize

    &:not(.has-line)
      m-ellipsis(100%)
      
  .classification
    margin-top: vh(10px)
    m-font-size(11, 16)
    color #aaa  

    &:not(.has-line)
      m-ellipsis(100%)

  .product-separator
    margin-right: vw(10px)
    margin-left: vw(10px)
    width: 1px
    height: 75%
    background-color: #000

  .product-line
    m-ellipsis(100%)
    m-font-size(12, 16)

  .category
    m-font-size(12, 16)
    text-transform: capitalize

  .price
    font-weight: bold

  .size
    color: #bbb
</style>