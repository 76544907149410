import axios from 'axios'
import { getUserIdLocalStorage } from '../utils/localStorageUtils'
/**
 * @typedef EventTrack
 * @property {any} data
 * @property {TRACK_NAMES_ENUM} name
 * @property {string} uuid
 */
/**
 * @enum {string}
 */
export const TRACK_NAMES_ENUM = {
  WELLCOME: 'View.Welcome',
  FOR: 'View.ForMe.ForGift',
  SEARCH: 'View.Search',
  CONCEPTS: 'View.Concepts',
  GENDER: 'View.Gender',
  RECOMMENDATION: 'View.Recommendations',
}
/**
 *
 * @param {EventTrack} event
 * @returns
 */
function track(event) {
  let eventTransmitted = {
    ...event,
    timestamp: new Date(Date.now()).getTime(),
    app: process.env.VUE_APP_ID,
    uuid: event.uuid ?? 'Guest:' + getUserIdLocalStorage(),
  }
  axios.defaults.headers.post['Content-Type'] = 'application/json'
  return axios
    .post(process.env.VUE_APP_API_URL + '/dashboard/saveAction', eventTransmitted)
    .then(response => {
      //console.log(response)
      return response
    })
    .catch(err => console.warn(err))
}
/**
 *
 * @param {TRACK_NAMES_ENUM} name
 * @param {Object} data
 */
export function trackEvent(name, data, uuid) {
  return track({ name, data, uuid })
}