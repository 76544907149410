<template>
  <div class="basic-selector-container">
    <div class="selects-wrapper">
      <basic-selector
        v-for="select in selectConfigs"
        :key="select.type"
        :title="select.name"
        :options="select.options"
        :open="selects[select.type]"
        :selected="selected[select.type] || []"
        @open="()=>handleOpen(select.type)"
        @change="(e)=>handleChange(select.type,e)"
        @close="()=>handleClose(select.type)"
      />
    </div>
    <div class="chips">
      <div v-for="chip in selectedChips" :key="chip.value + chip.key" class="chip">
        <div class="chip-text">{{ chip.label }}</div>
        <div class="close" @click="()=>handleRemoveChip(chip)" />
      </div>
    </div>
  </div>
</template>

<script>
import BasicSelector from './BasicSelector.vue'
export default {
  components: { BasicSelector },
  props: {
    selectConfigs: Array,
    selected: Object,
  },
  data() {
    return {
      selects: this.selectConfigs.reduce(
        (obj, selectConf) => ({ ...obj, [selectConf.type]: false }),
        {}
      ),
    }
  },
  computed: {
    selectedChips() {
      return Object.keys(this.selected).reduce((array, key) => {
        return array.concat(
          this.selected[key].map(value => {
            let label = this.selectConfigs
              .find(selectObj => selectObj.type === key)
              ?.options.find(opt => opt.value === value)?.label
            return { value, label, key }
          })
        )
      }, [])
    },
  },
  methods: {
    handleOpen(key) {
      if (!this.selects[key]) {
        Object.keys(this.selects).forEach(keyMap => {
          if (this.selects[keyMap]) this.selects[keyMap] = false
        })
      }
      this.selects[key] = !this.selects[key]
    },
    handleClose(key) {
      this.selects[key] = false
    },
    handleChange(key, data) {
      this.selected[key] = data
      //this.handleOpen(key)
    },
    handleRemoveChip(chip) {
      this.selected[chip.key].splice(this.selected[chip.key].indexOf(chip.value), 1)
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-selector-container
  .selects-wrapper
    display: flex
    align-items: center
    width: 100%

    .basic-selector
      margin-left: vw(20px)
      width: vw(200px)

      .mobile &
        flex: 1 1 0%

      &:first-child
        margin-left: 0

      &:last-child
        .mobile &
          >>> .menu-open
            left: calc(-100% - 20px)
            border-radius: 4px 0 4px 4px

          >>> .menu
            &.open
              border-radius: 4px 4px 0 0

              &:before,
              &:after
                right: 100%

              &:after
                border-right: 1px solid #ddd
                border-left: none
                border-radius: 0 0 50% 0

      >>> .menu-open
        width: calc(200% + 20px)

  .chips
    display: flex
    flex-wrap: wrap
    margin-top: vh(10px)

    .chip
      m-font-size(14, 20)
      display: flex
      align-items: center
      margin-top: vw(5px)
      margin-right: vw(15px)
      padding: vw(6px) vw(12px)
      background-color: #f2f2f2
      color: #000
      text-decoration: none
      white-space: nowrap

      .mobile &
        m-font-size(11, 15)

      &:last-child
        margin-right: 0

      .close
        m-icon("close", 20)
        margin-left: vw(5px)
        cursor: pointer
</style>