<template>
  <g>
    <defs>
      <path
        :id="'text-circle-'+instanceId"
        :d="textCirclePath"
      />
    </defs>
    <g v-for="family in familiesMapped" :key="family.id">
      <family-wedge
        :dr="dr"
        :radius="radius"
        :family-data="family"
        :start-angle="family.startAngle"
        :end-angle="family.endAngle"
      />
      <text
        class="family-ring-label"
              
        :dx="-family.wordLength"
      >
        <textPath
          :href="'#text-circle-'+instanceId"
          :startOffset="family.textOffset+'%'"
          font-weight="bold"
          alignment-baseline="middle"
          dominant-baseline="middle"
          fill="white"
          stroke="none"
        >
          {{ family.familyName.toUpperCase() }}
        </textpath>
      </text>
    </g>
  </g>
</template>

<script>
import FamilyWedge from './FamilyWedge.vue'
export default {
  components: { FamilyWedge },
  props: {
    radius: { type: Number, required: true },
    families: { type: Array, required: true },
    sdTheta: { type: Number, default: 1 },
    dr: { type: Number, default: 0.1 },
  },
  data() {
    return {
      instanceId: new Date().getTime(),
    }
  },
  computed: {
    familiesMapped() {
      const res = this.families.map(family => {
        const currentOffset = this.familyOffset
        let startAngle = this.familyOffset * this.sdTheta
        this.familyOffset += family.notes.length
        let endAngle = this.familyOffset * this.sdTheta

        let textOffset =
          (50 * (currentOffset + (family.notes.length - 1) / 2 + 0.5) * this.sdTheta) /
            (2 * Math.PI) +
          25
        /** #66 if there is only one family, then name is put on top */
        if(this.families.length===1){
          textOffset = 25  
        }
        
        let wordLength = family.family.name.length * 8
        let wedgeLength = family.notes.length * ((2 * Math.PI * this.radius) / this.allNotes.length)
        let familyName = wordLength < wedgeLength ? family.family.name : ''
        wordLength = familyName.length * 5
        return {
          ...family,
          startAngle,
          endAngle,
          textOffset,
          familyName,
          wedgeLength,
          wordLength,
        }
      })
      return res
    },
    allNotes() {
      return this.families.reduce((res, f) => res.concat(f.notes), [])
    },
    textCirclePath() {
      const textRadius = this.radius - this.dr / 2 - 1
      return (
        'M0 ' +
        textRadius +
        ' A ' +
        textRadius +
        ' ' +
        textRadius +
        ' 0 0 1 0 -' +
        textRadius +
        ' A ' +
        textRadius +
        ' ' +
        textRadius +
        ' 0 0 1 0 ' +
        textRadius +
        ' A ' +
        textRadius +
        ' ' +
        textRadius +
        ' 0 0 1 0 -' +
        textRadius +
        ' A ' +
        textRadius +
        ' ' +
        textRadius +
        ' 0 0 1 0 ' +
        textRadius
      )
    },
  },
  created() {
    this.familyOffset = 0
  },
  mounted(){
    this.familyOffset = 0
  },
  updated(){
    this.familyOffset = 0
  }
}
</script>

<style lang="stylus" scoped>
.family-ring-label
  m-font-size(11,11)
  .mobile &
    m-font-size(10,24)
  
</style>