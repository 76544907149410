<template>
  <div class="filtreable-recommendation">
    <div class="filters">
      <basic-selector-container :select-configs="filters" :selected="value" />
    </div>
  </div>
</template>

<script>
import QueryStringMixin from '../../mixins/QueryStringMixin'
import BasicSelectorContainer from '../basic/BasicSelectorContainer.vue'
export default {
  components: { BasicSelectorContainer },
  remoteComputed: {
    brands: {
      model: 'brands/index',
    },
    families: {
      model: 'families/index',
    },
  },
  mixins: [QueryStringMixin],
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    filters() {
      return [
        {
          type: 'families',
          name: this.$t('selector_families'),
          options: this.families
            ? this.families.map(family => ({ value: family.id.toString(), label: family.name }))
            : [],
        },
        {
          type: 'brand',
          name: this.$t('selector_brand'),
          options: this.filteredBrands
            ? this.filteredBrands.map(brand => ({ value: brand.id.toString(), label: brand.name }))
            : [],
        },
      ]
    },
    gender() {
      return null
    },
    filteredBrands() {
      const excludedBrand = process.env.VUE_APP_EXCLUDE_BRANDS.split(',')
      return this.brands.filter(b => !excludedBrand.some(brandId => b.id.toString()===brandId))
    },
  },
}
</script>

<style lang="stylus" scoped>
.filtreable-recommendation
  margin-right: vw(150px)
  margin-bottom: vh(30px)
  margin-left: vw(150px)

  .mobile &
    margin-right: auto
    margin-bottom: vw(20px)
    margin-left: auto
    width: 90%

  .filters
    display: flex
    align-items: center
    width: 100%

    .filter
      flex: 0 0 25%

      .mobile &
        flex: 1 1 0%

      &:not(:last-child)
        margin-right: vw(20px)

    >>> .basic-selector-container
      width: 100%
</style>