<template>
  <div class="section-title-wrapper">
    <div v-if="isMobile" class="mobile-box-top" />
    <div class="section-title">{{ title }}</div>
    <div v-if="subTitle" class="section-subtitle-wrapper">
      <div class="icon" />
      <div class="section-subtitle">{{ subTitle }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
    },
  },
}
</script>

<style lang="stylus" scoped>
$subtitle-color = #aaa
$margin-top-mobile = vh(50px)
$margin-top-mobile-negative = vh(-50px)

.section-title-wrapper
  display: flex
  flex-direction: column
  justify-content: center
  margin-right: vw(60px)
  margin-bottom: vh(40px)
  margin-left: vw(60px)
  padding-left: vw(10px)
  min-height: vh(50px)
  border-left: 5px solid $primary-theme-color

  .mobile &
    position: relative
    margin-top: $margin-top-mobile
    margin-left: vw(20px)
    padding-left: 0
    border-left: none

  .mobile-box-top
    position: absolute
    top: $margin-top-mobile-negative
    left: 0
    width: vw(60px)
    border-top: 5px solid $primary-theme-color

  .section-title
    m-font-size(22, 30)
    font-weight: 700
    // font-family: "Playfair Display"

    .mobile &
      m-font-size(20, 26)
      font-family: "AvenirNext"

    &:not(:only-child)
      margin-bottom: vh(5px)

      .mobile &
        margin-bottom: vw(15px)

.section-subtitle-wrapper
  display: flex
  align-items: center
  margin-bottom: vw(2px)

  .mobile &
    align-items: flex-start

  .icon
    m-icon("info-small", 20)
    margin-right: vw(5px)

    .mobile &
      m-icon("info-small", 24)

  .section-subtitle
    m-font-size(12, 16)
    flex: 1 1 0%
    margin-top: 0.1em
    color: $dark-gray
</style>