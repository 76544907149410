<template>
  <div v-click-outside="handleClose" class="basic-selector">
    <div class="menu" :class="{open}" @click="$emit('open')">
      <div class="text">{{ title }}</div>
      <div :class="['icon',iconName]" />
    </div>
    <transition name="fade-down">
      <!-- DESKTOP -->
      <div v-if="open && !isMobile" class="menu-open">
        <div class="menu-search">
          <basic-search v-model="search" :pladceholder-text="title + ' ' + $t('searching')" />
        </div>
        <div class="options-container">
          <div v-for="opt in filteredOptions" :key="opt.value" class="option">
            <basic-checkbox :value="isSelected(opt)" @change="(event)=>handleChange(opt,event)" />
            <div class="checkbox-label">{{ opt.label }}</div>
          </div>
        </div>
      </div>
    </transition>
    <!-- MOBILE -->
    <basic-desplegable-menu :open="isMobile && open" :title="title" @close="handleClose">
      <template #header>
        <div class="header-wrapper">
          <div class="main-header">
            <div class="close" @click="handleClose" />
            <div class="title">{{ title }}</div>
            <div class="close hide" />
          </div>
          <div class="menu-search">
            <basic-search v-model="search" :pladceholder-text="title + ' ' + $t('searching')" />
          </div>
        </div>
      </template>
      <div v-if="open" class="menu-open-mobile">
        <div class="options-container">
          <div v-for="opt in filteredOptions" :key="opt.value" class="option">
            <basic-checkbox :value="isSelected(opt)" @change="(event)=>handleChange(opt,event)" />
            <div class="checkbox-label">{{ opt.label }}</div>
          </div>
        </div>
      </div>
      <template #footer>
        <button class="black-btn" @click="handleClose">{{ $t('back') }}</button>
      </template>
    </basic-desplegable-menu>
  </div>
</template>

<script>
import vueClickOutside from 'vue-click-outside'
import BasicCheckbox from './BasicCheckbox.vue'
import BasicSearch from './BasicSearch.vue'
import BasicDesplegableMenu from './BasicDesplegableMenu.vue'
export default {
  components: { BasicCheckbox, BasicSearch, BasicDesplegableMenu },
  directives: {
    ClickOutside: vueClickOutside,
  },
  model: {
    prop: 'selected',
    event: 'change',
  },
  props: {
    options: {
      type: Array,
    },
    open: Boolean,
    title: String,
    cleanable: Boolean,
    selected: { type: Array, default: () => [] },
  },
  data() {
    return {
      search: '',
    }
  },
  computed: {
    iconName() {
      return this.selected.length > 0 ? 'selected' : this.open ? 'arrow rotate' : 'arrow'
    },
    filteredOptions() {
      if (!this.search) return this.options
      return this.options.filter(
        opt => opt.label.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
      )
    },
  },
  methods: {
    isSelected(option) {
      return this.selected.indexOf(option.value) >= 0
    },
    handleClose() {
      this.$emit('close')
    },
    handleChange(option) {
      const copy = [...this.selected]
      const index = this.selected.indexOf(option.value)
      if (index >= 0) {
        copy.splice(index, 1)
      } else copy.push(option.value)
      this.$emit('change', copy)
    },
  },
}
</script>

<style lang="stylus" scoped>
$background-open = #f2f2f2

.basic-selector
  position: relative

  .menu
    display: flex
    justify-content: space-between
    align-items: center
    padding: vw(15px)
    width: 100%
    height: vw(60px)
    border: 1px solid #ddd
    border-radius: 4px
    cursor: pointer
    transition: background-color 0.2s ease-in-out

    .mobile &
      padding: vw(5px)
      height: vw(40px)

    &.open
      position: relative
      z-index: 2
      border-bottom-color: $background-open
      border-radius: 4px 4px 0 0
      background-color: $background-open

      &:before
        position: absolute
        top: "calc(100% - %s + 1px)" % vw(15px)
        right: vw(-14px)
        display: block
        width: vw(15px)
        height: vw(15px)
        background-color: $background-open
        content: ""
        pointer-events: none

      &:after
        position: absolute
        top: "calc(100% - %s + 1px)" % vw(15px)
        right: vw(-15px)
        display: block
        width: vw(15px)
        height: vw(15px)
        border-bottom: 1px solid #ddd
        border-left: 1px solid #ddd
        border-radius: 0 0 0 50%
        background-color: #fff
        content: ""
        pointer-events: none

    .text
      margin: 0 vw(10px)

    .icon
      &.arrow
        m-icon("arrow-right", 25)
        transition: transform 0.2s ease-in-out
        transform: rotate(90deg)

        &.rotate
          transform: rotate(270deg)

      &.selected
        m-icon("selected-green", 30)

  .menu-open
    position: absolute
    z-index: 101
    overflow: hidden
    margin-top: -1px
    padding: vw(10px)
    width: 200%
    border: 1px solid #ddd
    border-radius: 0 4px 4px 4px
    background-color: $background-open

    &.fade-down-enter-active
      transition: all 0.3s

    &.fade-down-leave-active
      position: absolute
      transition: all 0.3s

    &.fade-down-enter,
    &.fade-down-leave-to
      opacity: 0

    .menu-search
      margin-bottom: vw(15px)

  .options-container
    overflow-y: auto
    max-height: vw(250px)
    height: auto

    .mobile &
      max-height: none

    .option
      display: flex
      align-items: center

      &:not(:last-child)
        margin-bottom: vw(15px)

    .container
      margin-right: vw(10px)

    .checkbox-label
      m-font-size(14, 22)
      margin-top: 0.2em

      .mobile &
        m-font-size(16, 22)

  .basic-desplegable-menu
    background-color: #fff

    .main-header
      display: flex
      align-items: center
      padding: vh(20px) 0

      .title
        flex: 1 1 100%
        text-align: center
        m-font-size(19, 22)
        font-weight: 600

      .close
        m-icon("arrow-right", 30)
        transform: rotate(180deg)

        &.hide
          opacity: 0
          pointer-events: none

    .menu-open-mobile
      margin-top: vh(10px)
      padding: vw(10px)

    .black-btn
      height: vh(150px)
      background-color: #000
      color: #fff
</style>