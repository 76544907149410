<template>
  <transition name="slide">
    <div v-if="open" class="basic-desplegable-menu">
      <slot name="header">
        <div class="close" @click="$emit('close')" />
        <div class="title">{{ title }}</div>
      </slot>
      <div class="body-wrapper">
        <slot /> 
      </div>
      <slot name="footer" />
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
.basic-desplegable-menu
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 1001
  display: flex
  flex-direction: column
  padding: vh(10px) vw(10px)
  background: #fff

  .body-wrapper
    flex: 1 1 100%
    overflow-y: auto

  .close
    m-icon("close", 30)

  &.slide-enter-active
    transition: all 0.5s

  &.slide-leave-active
    transition: all 0.5s

  &.slide-enter,
  &.slide-leave-to
    transform: translateX(100%)
</style>