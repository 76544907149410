<template>
  <div class="donut" :style="`transform:scale(${scale})`">
    <svg
      ref="node"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs />
      <g class="root">
        <circle class="outer-circle" :r="r" />
        <circle class="inner-circle" :r="r*0.35" />
        <g class="donut-wrapper" />
            
        <g v-if="showNames" class="ring-wrapper">
          <families-ring
            :sdTheta="sdTheta"
            :radius="r"
            :dr="isMobile?r*0.1:r*0.07"
            :families="data"
          />
        </g>
        
      </g>

    </svg>
  </div>
</template>

<script>
import d3 from '../../utils/d3Importer'
import FamiliesRing from './FamiliesRing.vue'
export default {
  components: { FamiliesRing },
  props: {
    height: { type: Number, required: true },
    width: { type: Number, required: true },
    data: { type: Array, required: true },
    showNotes: { type: Boolean, default: true },
    showNames: { type: Boolean, default: true },
    selection: { type: Number },
    subSelection: { type: Number },
    anchorOffset: { type: Number },
    scale: { type: Number },
  },
  computed: {
    sdTheta() {
      return (
        (2 * Math.PI) /
        this.data.reduce((sum, fam) => {
          return sum + fam.value
        }, 0)
      )
    },
    r() {
      const height = this.height
      const width = this.width
      if (this.isMobile) return (Math.min(height, width) * 0.92) / 2
      return (Math.min(height, width) * 0.85) / 2
    },
  },
  mounted() {
    this.drawDonut()
  },
  updated() {
    this.drawDonut()
  },
  methods: {
    drawDonut() {
      let self = this
      const node = this.$refs.node

      var svg = d3.select(node)

      // const selection = this.props.label;
      const height = this.height
      const width = this.width
      const r = this.r //Math.min(height,width)*0.8/2
      const innerR = r * 0.35
      const smallR = r * 0.6
      this.innerR = innerR
      this.smallR = smallR
      this.familyDr = this.isMobile ? this.r * 0.07 : this.r * 0.05
      svg.attr('height', height)
      svg.attr('width', width)

      // let root = svg.selectAll('.root').data(['root'],(d)=>d)
      // let rootEnter = root.enter().append('g').attr('class','root')
      // root = root.merge(rootEnter)
      let root = svg.select('.root')

      let donutRoot = root.select('.donut-wrapper')

      let data = JSON.parse(JSON.stringify(this.data))
      var arc = d3
        .arc()
        .innerRadius(innerR)
        .outerRadius(r)
        .padAngle(2.5 / r)

      var smallArc = d3
        .arc()
        .innerRadius(innerR - 1)
        .outerRadius(() => (this.showNotes ? smallR : innerR))
        .padAngle(0)
      var smallArcSelection = d3
        .arc()
        .innerRadius(innerR - 1)
        .outerRadius(() => (this.showNotes ? smallR : innerR))
        .padAngle(0.01)

      var smallFamilyArc = d3
        .arc()
        .innerRadius(smallR - this.familyDr)
        .outerRadius(smallR)
        .padAngle(0)

      var pie = d3
        .pie()
        .sort(null) // .sort(this.compareFamilies)
        .value(function(d) {
          return d.value
        })
        // cuidao, hacemos "esta locura" para matchear con el familiesRing
        // .startAngle(this.sdTheta / 2 + Math.PI + Math.PI * 2)
        // .endAngle(this.sdTheta / 2 + Math.PI);
        .startAngle(0)
        .endAngle(2 * Math.PI)

      var arcTween = function(a) {
        let node = this.parentNode
        var i = d3.interpolate(node._current, a)
        node._current = i(0)
        return function(t) {
          return arc(i(t))
        }
      }
      var smallArcTween = function(a) {
        let node = this.parentNode
        var i = d3.interpolate(node._current, a)
        let r0 = self.showNotes ? smallR : innerR
        var ri = d3.interpolate(node._current.data.radius || r0, a.data.radius)
        var ease
        if (node._current.data.radius < a.data.radius) ease = d3.easeBackOut
        else ease = d3.easeBackIn
        node._current = i(0)
        node._current.data.radius = ri(0)
        return function(t) {
          return smallArc.outerRadius(ri(ease(t)))(i(t))
        }
      }
      var arcTweenDeep = function(a) {
        let node = this.parentNode.parentNode
        var i = d3.interpolate(node._current, a)
        node._current = i(0)
        return function(t) {
          return arc(i(t))
        }
      }

      function findNeighborArc(i, data0, data1, key) {
        var d = findPreceding(i, data0, data1, key)
        if (d) return { data: d.data, startAngle: d.endAngle, endAngle: d.endAngle }
        d = findFollowing(i, data0, data1, key)
        if (d) return { data: d.data, startAngle: d.startAngle, endAngle: d.startAngle }
        return null
      }

      // Find the element in data0 that joins the highest preceding element in data1.
      function findPreceding(i, data0, data1, key) {
        var m = data0.length
        while (--i >= 0) {
          var k = key(data1[i])
          for (var j = 0; j < m; ++j) {
            if (key(data0[j]) === k) return data0[j]
          }
        }
      }

      // Find the element in data0 that joins the lowest following element in data1.
      function findFollowing(i, data0, data1, key) {
        var n = data1.length,
          m = data0.length
        while (++i < n) {
          var k = key(data1[i])
          for (var j = 0; j < m; ++j) {
            if (key(data0[j]) === k) return data0[j]
          }
        }
      }

      //////////////////////////////////////////
      // clip paths
      //////////////////////////////////////////
      // let clips = donutRoot
      //   .selectAll(".clip-path")
      //   .data(pie(data), function(d, i) {
      //     return d.data.id;
      //   });

      // let clipsEnter = clips
      //   .enter()
      //   .append("clipPath")
      //   .attr("class", "clip-path")
      //   .attr("id", d => "clip-" + d.data.id);
      // // .attr('clipPathUnits',"objectBoundingBox")

      // clipsEnter
      //   .append("path")
      //   .attr("fill", "#000")
      //   .attr("stroke", "#fff")
      //   .attr("d", arc)
      //   .each(function(d) {
      //     this._current = d;
      //   });

      // clips.exit().remove();

      // clips = clips.merge(clipsEnter);
      // // clips.select('path').attr('d',arc)
      // clips
      //   .select("path")
      //   .transition()
      //   .duration(900)
      //   .attrTween("d", arcTween);

      //////////////////////////////////////////
      // actual items
      // - de momento no hacen falta para nada...
      //////////////////////////////////////////

      let key = d => {
        return d.data.family.id
      }
      let items = donutRoot.selectAll('.item')
      var data0 = items.data(),
        data1 = pie(data)

      items = items.data(data1, key)

      let itemsEnter = items
        .enter()
        .append('g')
        .attr('class', 'item')
      itemsEnter.each(function(d, i) {
        this._current = findNeighborArc(i, data0, data1, key) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
        }
      })

      itemsEnter
        .append('path')
        .attr('class', 'main-wedge')
        .attr('fill', function(d) {
          return d.data.family.color
        })
      itemsEnter.append('g').attr('class', 'image-container')

      itemsEnter
        .append('clipPath')
        .attr('class', 'clip-path')
        .attr('id', d => {
          return 'donut-clip-' + this.instanceId + '-' + d.data.family.id
        })
        .append('path')
        .attr('fill', function() {
          return '#000'
        })

      let instanceId = this.instanceId
      itemsEnter.each(function(d) {
        let centroid = arc.centroid(d)
        let x = centroid[0]
        let y = centroid[1]
        let theta = Math.atan2(y, x)
        let imageSize = 3 * r //Math.abs(d.endAngle-d.startAngle)>Math.PI ? 3*r : 3*r
        x = (Math.cos(theta) * r) / 2 - imageSize / 2
        y = (Math.sin(theta) * r) / 2 - imageSize / 2
        const img = document.createElementNS('http://www.w3.org/2000/svg', 'image')
        const href = d.data.family.imageUrl
        img._currentTheta = theta
        img.onload = () => {
          d3.select(this)
            .select('.image-container')
            .append(() => img)
            .attr('width', imageSize)
            .attr('height', imageSize)
            .style('transform-origin', x + imageSize / 2 + 'px ' + (y + imageSize / 2) + 'px')
            .attr('x', x)
            .attr('y', y)
          d3.select(this).attr(
            'clip-path',
            'url(#donut-clip-' + instanceId + '-' + d.data.family.id + ')'
          )
        }
        img.onerror = () => {
          // d3.select(this).style('background-image',`url(${noImage})`)
          d3.select(this).classed('no-image', true)
        }
        if (href) img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', href)

        // BASURA EXPLORER QUE NO DISPARA "onload"...
        // if (window.bowser.browser.name.indexOf('Explorer') >= 0 || window.bowser.browser.name.indexOf('Edge') >= 0 || window.bowser.browser.name.indexOf('Safari') >= 0) {
        //if (true) {
        setTimeout(() => {
          img.onload()
        }, 400)
        //}
      })

      itemsEnter.select('.main-wedge').on('click', (e,d) => {
        this.$emit('select', d.data.family.id)
      })

      let itemsExit = items
        .exit()
        .datum(function(d, i) {
          let result = findNeighborArc(i, data1, data0, key) || {
            data: data0[i].data,
            startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
            endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          }
          d.startAngle = result.startAngle
          d.endAngle = result.endAngle
          return d
        })
        .transition()
        .duration(900)
      itemsExit.select('path').attrTween('d', arcTween)
      itemsExit
        .select('.clip-path')
        .select('path')
        .attrTween('d', arcTweenDeep)
      itemsExit.remove()
      // items.exit().remove()

      items = items.merge(itemsEnter)
      let rawItems = items

      items.classed('selected', d => {
        return d.data.family.id === this.selection
      })

      items = items.transition().duration(900)
      items.select('path').attrTween('d', arcTween)
      items
        .select('.clip-path')
        .select('path')
        .attrTween('d', arcTweenDeep)

      // let items = donutRoot.selectAll('.item')
      //   .data(pie(data),function(d,i) {
      //     return d.data.family.id
      //   })
      // let itemsEnter = items.enter().append('g').attr('class',(d)=>'item '+d.data.name)
      // itemsEnter.append('path').attr('fill',(d)=>{return d.data.color}).attr('stroke','#fff')
      // items.exit().remove()
      // VERY FAKES ESTOS "300": Should be the actual size of the original image! (all the same, or measured at runtime... )

      items.select('image').each(function(d) {
        let centroid = arc.centroid(d)
        // let sel = d3.select(this)
        //   .transition()
        //   .duration(900)
        let x = centroid[0]
        let y = centroid[1]
        let theta = Math.atan2(y, x)
        let imageSize = 3 * r //Math.abs(d.endAngle-d.startAngle)>Math.PI ? 3*r : 3*r
        x = (Math.cos(theta) * r) / 2 - imageSize / 2
        y = (Math.sin(theta) * r) / 2 - imageSize / 2
        // sel.attr("x", x - 2*r);
        // sel.attr("y", y - 2*r);

        d3.select(this)
          .transition()
          .duration(900)
          .ease(d3.easeCubicInOut)
          .attr('width', imageSize)
          .attr('height', imageSize)
          .style('transform-origin', x + imageSize / 2 + 'px ' + (y + imageSize / 2) + 'px')
          .attr('x', x)
          .attr('y', y)

        // let circleXY = function() {
        //   while (this._currentTheta-theta > Math.PI) theta+=2*Math.PI
        //   while (this._currentTheta-theta < -Math.PI) theta-=2*Math.PI
        //   var i = d3.interpolate(this._currentTheta || 0, theta);
        //   this._currentTheta = i(1);
        //   let node = this
        //   return function(t) {
        //     let x = Math.cos(i(t))*r/2 - imageSize/2
        //     let y = Math.sin(i(t))*r/2 - imageSize/2
        //     node.setAttribute("x", x);
        //     node.setAttribute("y", y);
        //     // node.setAttribute("transform-origin", (x + imageSize/2) + " " + (y+imageSize/2))
        //     // node.setAttribute(
        //     //   "transform",
        //     //   "rotate(" + -(180*self.currentAngle/Math.PI)+ ") translate(" + 0 + "," + 0 + ") "
        //     // );
        //   };
        // }
        // sel.tween("attr.xy", circleXY)

        // .attr("transform-origin", (x + 2*r) + " " + (y+2*r))
        // .attr(
        //   "transform",
        //   "rotate(" + -self.currentAngle + ") translate(" + 0 + "," + 0 + ") "
        // );
      })
      // clips.each(function(d) {
      //   let centroid = arc.centroid(d);
      //   let sel = d3.select(this);
      //   let x = centroid[0];
      //   let y = centroid[1];
      //   sel.attr("x", x - 300);
      //   sel.attr("y", y - 300);
      //   sel.attr("transform-origin", x + " " + y);
      //   sel.attr("transform", "rotate(" + self.currentAngle + ")");
      // });

      // SUBWEDGES ...
      let subItems = rawItems.selectAll('.sub-wedge').data(
        d => {
          var subPie = d3
            .pie()
            .sort(null) //.sort(this.compareSubFamilies)
            .value(function(dd) {
              return dd.value
            })
            // cuidado, hacemos "esta locura" para matchear con el familiesRing
            // .startAngle(this.sdTheta / 2 + Math.PI + Math.PI * 2)
            // .endAngle(this.sdTheta / 2 + Math.PI);
            .startAngle(d.startAngle)
            .endAngle(d.endAngle)

          let sp = subPie(d.data.notes)
          sp.forEach(n => {
            // console.log(d.startAngle)
            n.data.offset = n.startAngle + Math.PI + (n.endAngle - n.startAngle) / 2 //-data1[0].startAngle//+(n.endAngle-n.startAngle)/2
            while (n.data.offset > 2 * Math.PI) n.data.offset -= 2 * Math.PI
            while (n.data.offset < 0) n.data.offset += 2 * Math.PI
            n.data.offset = (50 * n.data.offset) / (2 * Math.PI)
            n.data.radius = this.showNotes ? smallR : innerR
            n.data.family = d.data.family.id
          })

          d.subdata0 = d.subdata1 || []
          d.subdata1 = sp

          return sp
        },
        dd => dd.data.id
      )
      let subItemsEnter = subItems
        .enter()
        .append('g')
        .attr('class', 'sub-wedge')
      subItemsEnter
        .append('path')
        .attr('class', 'sub-wedge-path')
        .attr('d', d => {
          return smallArc.outerRadius(() => (this.showNotes ? smallR : innerR))(d)
        })
        .attr('opacity', '0')

      subItemsEnter.each(function(d, i) {
        this._current = findNeighborArc(
          i,
          d3.select(this.parentNode).datum().subdata0,
          d3.select(this.parentNode).datum().subdata1,
          (d, i) => i
        ) || {
          data: {},
          startAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
          endAngle: d.startAngle + (d.endAngle - d.startAngle) / 2,
        }
      })
      subItemsEnter.on('click', (e,d) => {
        this.$emit('selectSub', { id: d.data.id, family: d.data.family })
      })
      let subItemsWedgesEnter = subItemsEnter
        .append('g')
        .attr('class', 'sub-family-wedge')
        .attr('opacity', 0)
      subItemsWedgesEnter.append('path').attr('class', 'sub-family-wedge-path')
      subItemsWedgesEnter
        .append('text')
        .attr('class', 'sub-family-text')
        // .attr('dx',(d)=>-d.data.name.length*3.4)
        .append('textPath')
        //href={"#text-circle-"+this.instanceId}
        // startOffset={(100-textOffset)/2+'%'}
        .attr('href', '#text-small-circle-' + this.instanceId)
        .attr('dominant-baseline', 'middle') //for poor FireFox...
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'middle')
        .attr('startOffset', d => {
          return d.data.offset + '%'
        })

      subItemsEnter
        .append('path')
        .attr('class', 'sub-wedge-selection')
        .attr('d', d => {
          return smallArcSelection.outerRadius(smallR)(d)
        })
        .attr('stroke', '#fff')
        .attr('stroke-width', 4)
        .attr('fill', 'none')
        .attr('opacity', '0')

      subItems.exit().remove()

      subItems = subItems.merge(subItemsEnter)

      subItems
        .select('.sub-wedge-path')
        .transition()
        .duration(700)
        // .ease(d3.easeBack)
        // .attr("d", smallArc)
        .attrTween('d', smallArcTween)
        .attr('opacity', '1')
        // .attr('fill','rgba(0,0,0,0.8')
        .attr('fill', () => `rgba(#ffffff,0.7)`)
      subItems
        .select('.sub-wedge-selection')
        .attr('d', d => {
          return smallArcSelection.outerRadius(smallR)(d)
        })
        .transition()
        .attr('opacity', d => {
          if (this.showNotes && d.data.id === this.subSelection && d.data.family === this.selection)
            return 1
          else return 0
        })

      subItems.each(function(d) {
        if (self.showNotes && d.data.id === self.subSelection && d.data.family === self.selection)
          this.parentNode.appendChild(this)
      })

      subItems
        .select('.sub-family-wedge')
        .select('path')
        .attr('d', d => {
          return smallFamilyArc(d)
        })
        .attr('fill', d => d.data.color)
      let selection
      let wedge
      if (this.showNotes && this.subSelection) {
        selection = this.selection || (data1[0] && data1[0].data.id)
        let fam = data1.find(d => d.data.family.id === selection)
        wedge = fam.subdata1.find(d => {
          return d.data.id === this.subSelection
        })
      } else {
        selection = this.selection || (data1[0] && data1[0].data.id)
        wedge = data1.find(d => {
          return d.data.family.id === selection
        })
      }
      if (wedge) {
        let newAngle =
          -(wedge.startAngle + (wedge.endAngle - wedge.startAngle) / 2) -
          Math.PI / 2 +
          (this.anchorOffset || 0)
        if (!this.currentAngle) {
          this.currentAngle = 0
        }
        while (newAngle - this.currentAngle > Math.PI) {
          newAngle = newAngle - 2 * Math.PI
        }
        while (newAngle - this.currentAngle < -Math.PI) {
          newAngle = newAngle + 2 * Math.PI
        }
        this.currentAngle = newAngle
      }
      root.attr(
        'transform',
        'translate(' +
          width / 2 +
          ',' +
          height / 2 +
          ') rotate(' +
          (180 * this.currentAngle) / Math.PI +
          ')'
      )
    },
  },
}
</script>

<style lang="stylus" scoped>
.donut
  position: relative

  // height: 100%;
  // width: 100%;

  svg
    width: 100%;
  
  .root
    transform: translate(50%, 50%)

  .clip-path
    transition: transform 1s

  .outer-circle,
  .inner-circle
    stroke: #ccc

  .outer-circle
    fill: none // #F7F7F7;

  .inner-circle
    fill: none // #F7F7F7;

  .ring-wrapper
    pointer-events: none

    .family-ring-label
      font-size: 14px

    .mobile &
      .family-ring-label
        font-size: 10px

  .sub-family-text
    font-size: 10px
    fill: #fff

    .mobile &
      font-size: 8px

  .label-circle
    fill: none
    stroke: #888

  .donut-label
    // @include font-size(12);
    font-size: 12px
    fill: #888
    stroke: none

  .avatar
    fill: #f00

  .item
    // &.selected {
    // path {
    // stroke: #444;
    // stroke-width: 4px;
    // }
    // }
    .image-container
      pointer-events: none

    .sub-family-wedge
      pointer-events: none

    image
      cursor: pointer
      transition: transform 1s
      transform: rotate(180deg)

    &.no-image
      image
        display: none

  .wheel-center
    position: absolute
    top: 50%
    left: 50%
    // background-image: url("../../assets/icons/nocibe.png");
    border: solid 1px #888
    border-radius: 140px
    background-color: white
    background-position: center
    background-size: 80%
    background-repeat: no-repeat
    transform: translate(-50%, -50%)

  .selection-mark
    position: absolute
    width: 20px
    height: 20px

    // .line {
    // width: 100%;
    // height: 1px;
    // background: #444;
    // opacity: 0.3;
    // }
    .arrow
      width: 0
      height: 0
      border-style: solid

    &.mark-right
      top: 50%
      right: 0
      transform: translate(0, -50%)

      .arrow
        border-width: 8px 12px 8px 0
        border-color: transparent #533 transparent transparent

    &.mark-bottom
      bottom: 0
      left: 50%
      transform: translate(-50%, 0)

      .arrow
        border-width: 0 8px 10px 8px
        border-color: transparent transparent #533 transparent
</style>