<template>
  <g>
    <path
      :d="arc"
      :fill="familyData.family.color"
      stroke="none"
      opacity="1"
    />
  </g>
</template>

<script>
import d3 from '../../utils/d3Importer'
export default {
  props: {
    radius: { type: Number, required: true },
    dr: { type: Number, required: true },
    familyData: { type: Object, required: true },
    startAngle: { type: Number, required: true },
    endAngle: { type: Number, required: true },
  },
  computed: {
    arc() {
      const arc = d3
        .arc()
        .outerRadius(this.radius)
        .innerRadius(this.radius - this.dr)
        .padAngle(0.007)
      var startAngle = this.startAngle
      var endAngle = this.endAngle
      arc.startAngle(startAngle).endAngle(endAngle)
      return arc()
    },
  },
}
</script>

<style lang="stylus" scoped></style>